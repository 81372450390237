import logoLink from '../resources/reflex.svg';
import companyLogoLink from '../resources/reflex.svg';
import footerLogoLink from '../resources/reflex_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#84BD3F',
    panel_highlight: '#84BD3F',
    chat_background_right: '#eceded',
    chat_background_left: '#e6f2d8',

    active: '#84BD3F',
    inactive: '#000',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#cee5b2',

    phonebook_dropdown: '#cee5b2',
    phonebook_dropdown_svg: '#000',

    primary_light: '#9cca65',
    primary: '#84BD3F',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#000',
    text_on_secondary: 'white',
    focus_active: '#84BD3F',

    red: '#CD0529',
    green: '#84BD3F',

    connecting: '#c6c7c8',
    connected: '#84BD3F',
    aborted: '#CD0529',

    font_color: 'black',
    link_color: '#84BD3F',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#656565',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#84BD3F',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Reflex Winkelmann GmbH';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
